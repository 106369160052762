import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Typography, Row, Col, Skeleton, Select, Table, Button, Space, Alert, Modal, Spin, Checkbox, Form, Tooltip } from 'antd';
const {Title, Text} = Typography;

import Cookies from "js-cookie";
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { DataService } from '../../config/dataService/dataService';
import { OCMModal, OCMModalHeader, ProjectsDiv } from '../tools/Style';
import { Gantt } from '../../local_modules/react-gantt';
import { createGlobalStyle } from 'styled-components';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setCloseProject, setReopenProject } from '../../redux/project/actionCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { saveLoginUserCookie } from '../../utility/utility';
import { useNavigate } from 'react-router-dom';
import AddNewProject from '../../layout/widgets/AddNewProject';
import AddAudienceModel from '../tools/panel/widgets/target_audiences/AddAudienceModel';
import useNotificationController from '../../utility/useNotificationController';

function ProjectManagement(props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    contextHolder,
    openError,
    openSuccess,
  } = useNotificationController();
  
  const date_time_format = localStorage.getItem('date_time_format');
  const [selectedProject, setSelectedProject] = useState([]);
  const [currentSelectedProject, setCurrentSelectedProject] = useState(0);
  const [currentSelectedProjectName, setCurrentSelectedProjectName] = useState('');
  const [newSelectedProject, setNewSelectedProject] = useState([]);
  const [timelineProject, setTimelineProject] = useState({id: "all", projectName: "All Projects"});
  const [activateProject, setActivateProject] = useState(false);
  const [hasActiveOnSelectedProject, setHasActiveOnSelectedProject] = useState(false);
  const [buttonText, setButtonText] = useState("Proceed");
  const GlobalStyle = createGlobalStyle`
    body {
      .gantt-tree-list-item
      {
        font-size: 12px !important;
        line-height: 13px;
        color: rgb(185,182,182);
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 67px;
      }
      .gantt-bars-row-item-bar
      {
        border-radius: 20px !important;
      }
      .gantt-tree-wrap
      {
        width: 206px !important;
      }
      .gantt-tree-icon
      {
        display:none;
      }
      button.gantt-tree-list-item
      {
        padding-left: 10px !important;
      }
    }
  `;

  const [projects, setProjects] = useState({
    loaded: false,
    data: [],
  });

  const [createNewActiveProject, setCreateNewActiveProject] = useState(false);
  const [addTargetAudienceModalOpen, setAddTargetAudienceModalOpen] = useState(false);
  const [initiateModalOpen, setInitiateModalOpen] = useState(false);
  const [reloadOnClose, setReloadOnClose] = useState(false);
  const [timeline, setTimeline] = useState();
  const [tableData, setTableData] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [showProject, setShowProject] = useState('all');
  const [confirmLoadingDeleteButton, setConfirmLoadingDeleteButton] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmLoadingNoLoader, setConfirmLoadingNoLoader] = useState(false);
  const [confirmLoadingProcced, setConfirmLoadingProcced] = useState(false);
  const [openProjects, setOpenProjects] = useState(0);
  const [closeProjects, setCloseProjects] = useState(0);
  const [currentFilter, setCurrentFilter] = useState("all");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreateProjectModalVisible, setIsCreateProjectModalVisible] = useState(false);
  const [isSingleModalVisible, setIsSingleModalVisible] = useState(false);
  const [ClosedCount, setClosedCount] = useState(0);
  const [closedProjectId, setClosedProjectId] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [checkState, setCheckState] = useState({
    checked: false,
  });

  const handleChange = (pagination, filter, sorter) => {
    setSortedInfo(sorter);
  };

  const changeShowProject = (value) => {
    setShowProject(value);
    createData(projects.data, value);
    createItems(projects.data, value);
    setCurrentFilter(value);
  };

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
      switch (action.type) {
        case 'SAVE_REOPEN_PROJECT_ERR':
          return { ...state, error: true,messages: action.err };
        case 'SET_CLOSE_PROJECT_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const closeProject = useCallback(async (data) => {
      const rememberMe = Cookies.get('rememberMe');
      data['rememberMe'] = rememberMe;
      apiResponseCallback({ type: 'RESET' });
      dispatch(
          setCloseProject({data},(response) => {
          if (response.status === 200) {
            saveLoginUserCookie(response);
            let tmpTableData = projects.data;
            let len = data.length;
            let ind = 0;
            let tmp = [];
            let tmpTable = [];
            tmpTableData.map(item => {
              if (data.includes(item.id))
              {
                item.isClosed = true;
                tmp.push(item);
              }
              else
              {
                tmp.push(item);
              }
            });
            tableData.map(item => {
              if (data.includes(item.key))
              {
                item.isClosed = true;
                tmpTable.push(item);
              }
              else
              {
                tmpTable.push(item);
              }
            });
            const openProject = tmp.filter((n) => !n.isClosed).length;
            const closeProject = tmp.filter((n) => n.isClosed).length;
            setOpenProjects(openProject);
            setCloseProjects(closeProject);
            setSelectedProject([]);
            setTableData(tmpTable);
            setProjects({...projects, data: tmp});
            if (len > 1)
            {
              openSuccess('bottomRight', "Archieve Project",  `Selected Projects Archived successfully`);
            }
            else
            {
              openSuccess('bottomRight', "Archieve Project",  `Selected Project Archived successfully`);
            }
            setIsModalVisible(false);
            setIsSingleModalVisible(false);
            setConfirmLoading(false);
            setConfirmLoadingNoLoader(false);
            setCurrentSelectedProject(0);
            setCurrentSelectedProjectName('');
            setNewSelectedProject([]);
            setActivateProject(false);
            setButtonText("Proceed");
            setCheckState({checked: false});
            if (createNewActiveProject)
            {
              setIsCreateProjectModalVisible(true);
            }
            props.rearrangeProject();
          } else {
            setIsModalVisible(false);
            setIsSingleModalVisible(false);
            setConfirmLoading(false);
            setConfirmLoadingNoLoader(false);
            setCheckState({checked: false});
            setCurrentSelectedProject(0);
            setCurrentSelectedProjectName('');
            setNewSelectedProject([]);
            setActivateProject(false);
            setButtonText("Proceed");
            openError("topLeft", "Archieve Project Error", response.err)
            apiResponseCallback(response);
          }
        }),
      );
  }, [dispatch, tableData, selectedProject, openProjects, closeProjects, projects, createNewActiveProject]);

  const reOpenProject = useCallback(async (data) => {
      const rememberMe = Cookies.get('rememberMe');
      data['rememberMe'] = rememberMe;
      apiResponseCallback({ type: 'RESET' });
      dispatch(
          setReopenProject({data},(response) => {
          if (response.status === 200) {
            saveLoginUserCookie(response);
            let tmpTableData = projects.data;
            let len = data.length;
            let ind = 0;
            let tmp = [];
            let tmpTable = [];
            tmpTableData.map(item => {
              if (data.includes(item.id))
              {
                item.isClosed = false;
                tmp.push(item);
              }
              else
              {
                tmp.push(item);
              }
            });
            tableData.map(item => {
              if (data.includes(item.key))
              {
                item.isClosed = false;
                tmpTable.push(item);
              }
              else
              {
                tmpTable.push(item);
              }
            })
            const openProject = tmp.filter((n) => !n.isClosed).length;
            const closeProject = tmp.filter((n) => n.isClosed).length;
            setOpenProjects(openProject);
            setCloseProjects(closeProject);
            setTableData(tmpTable);
            setProjects({...projects, data: tmp});
            setSelectedProject([]);
            if (len > 1)
            {
              openSuccess('bottomRight', "Reopen Project",  `Selected Projects Reopen successfully`);
            }
            else
            {
              openSuccess('bottomRight', "Reopen Project",  `Selected Project Reopen successfully`);
            }
            props.rearrangeProject();
          } else {
            openError("topLeft", "Reopen Project Error", response.err)
            apiResponseCallback(response);
          }
        }),
      );
  }, [dispatch, tableData, selectedProject, openProjects, closeProjects, projects]);

  const closeSelectedProject = () => {
      if (selectedProject.length === 0)
      {
        return ;
      }
      var noOfProject = 0;
      projects.data.map(project => {
        noOfProject++;
      });
      
      if (noOfProject === selectedProject.length)
      {
        setConfirmLoadingProcced(false);
        setIsSingleModalVisible(false);
        setIsModalVisible(false);
        setClosedProjectId(selectedProject);
        setIsCreateProjectModalVisible(true);
      }
      else
      {
        closeProject(selectedProject);
      }
  }

  const reopenSelectedProject = () => {
    if (selectedProject.length === 0)
    {
      return ;
    }
    reOpenProject(selectedProject);
  }

  const rowSelection = {
    selectedRowKeys: selectedProject,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedProject(selectedRowKeys);
      selectedRowKeys.map(item => {
        if (parseInt(item) === parseInt(localStorage.getItem('active_project_id')))
        {
          setHasActiveOnSelectedProject(true);
        }
      })
    },
    onSelect: (record, selected, selectedRows) => {
      let isClosed = 0;
      for(var ind in selectedRows)
      {
        const selectedRow = selectedRows[ind];
        if (selectedRow.isClosed)
        {
          isClosed++;
        }
        else if (selected && !selectedRow.isClosed)
        {
          isClosed++;
        }
      }
      setTimeout(() => {
        setClosedCount(isClosed);
      }, 1500)
      setSelectedProject(...selectedProject, record.key);
      if (parseInt(record.key) === parseInt(localStorage.getItem('active_project_id')))
      {
        setHasActiveOnSelectedProject(true);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected)
      {
        setSelectedProject(selectedRows.map(item => item.key));
        setHasActiveOnSelectedProject(true);
      }
      else
      {
        setSelectedProject([]);
        setHasActiveOnSelectedProject(false);
      }
    },
  };

  const removeTags = (str) => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  const makeActive = async (e, item) => {
    let currentSelectedProject = item.key;
    const data = {
      project_id: currentSelectedProject,
    };
    setConfirmLoadingProcced(true);
    const response = await DataService.post(
      "/users/setActiveProject/account_users",
      data
    );

    if (response.status === 200)
    {
        setConfirmLoadingProcced(false);
        localStorage.setItem('active_project_name', item.projectName);
        localStorage.setItem('active_project_id', item.key);
        const organizationalSlug = localStorage.getItem('organizationalSlug');
        const locations = location.pathname.split("/");
        props.rearrangeProject();
        navigate(`/${organizationalSlug}/main-dashboard`);
    }
    else
    {
      setConfirmLoadingProcced(false);
      openError("topLeft", "Archieve Project Error", response.err)
    }
  }

  const makeCurrentActive = async (item) => {
    let currentSelectedProject = item.id;
    const data = {
      project_id: currentSelectedProject,
    };
    setConfirmLoadingProcced(true);
    const response = await DataService.post(
      "/users/setActiveProject/account_users",
      data
    );

    if (response.status === 200)
    {
      setConfirmLoadingProcced(false);
      localStorage.setItem('active_project_name', item.projectName);
      localStorage.setItem('active_project_id', item.id);
    }
    else
    {
      setConfirmLoadingProcced(false);
      openError("topLeft", "Archieve Project Error", response.err)
    }
  }

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: (a, b) => (a.projectName.toLowerCase() < b.projectName.toLowerCase() ? -1 : 1),
      sortOrder: sortedInfo.columnKey === 'projectName' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return (
          <Text onClick={(e) => makeActive(e, record)} underline style={{cursor: "pointer"}}>{text}</Text>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'isClosed',
      key: 'isClosed',
      sorter: (a, b) => (removeTags(a.isClosed).toLowerCase() < removeTags(b.isClosed).toLowerCase() ? -1 : 1),
      sortOrder: sortedInfo.columnKey === 'isClosed' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return (
          <Select
            value={text}
            defaultValue={text}
            className={text === true ? 'close' : 'active'}
            onChange={(value) => {
              if (value)
              {
                var noOfProject = 0;
                projects.data.map(project => {
                  noOfProject++;
                });
                if (noOfProject === 1)
                {
                  setHasActiveOnSelectedProject(true);
                }
                setCurrentSelectedProject(record.key);
                setCurrentSelectedProjectName(record.projectName);
                setNewSelectedProject([]);
                setActivateProject(false);
                if (parseInt(record.key) === parseInt(localStorage.getItem('active_project_id')))
                {
                  setHasActiveOnSelectedProject(true);
                  setConfirmLoadingNoLoader(true);
                  setButtonText("Proceed");
                }
                
                if (projects.data.filter(project => project.isClosed !== true).filter(project => !selectedProject.includes(project.id)).length > 0)
                {
                  if (noOfProject > 1)
                  {
                    if (noOfProject > 1)
                    {
                      setCheckState({checked: true});
                    }
                  }
                }
                else if (projects.data.filter(project => project.isClosed !== true).filter(project => !selectedProject.includes(project.id)).length === 0)
                {
                  setCheckState({checked: false});
                }
                setShowTooltip(false);
                setIsSingleModalVisible(true);
              }
              else
              {
                let data = [];
                data.push(record.key);
                reOpenProject(data);
              }
            }}
          >
            <Option value={false}>Active</Option>
            <Option value={true}>Archived</Option>
          </Select>
        );
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a, b) => (a.createdBy.toLowerCase() < b.createdBy.toLowerCase() ? -1 : 1),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
      sorter: (a, b) => (a.createdOn.toLowerCase() < b.createdOn.toLowerCase() ? -1 : 1),
      sortOrder: sortedInfo.columnKey === 'createdOn' ? sortedInfo.order : null,
    },
    {
      title: 'Project Owner',
      dataIndex: 'productOwner',
      key: 'productOwner',
      sorter: (a, b) => (a.productOwner.toLowerCase() < b.productOwner.toLowerCase() ? -1 : 1),
      sortOrder: sortedInfo.columnKey === 'productOwner' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return (
          text === '' ? '' : (
            <div style={{lineHeight: "12px"}}>
            <Text>{`${text}`}</Text>
            <small style={{paddingLeft: "5px"}}>({record.userType})</small>
            </div>
          )
        );
      },
    },
    {
      title: 'Other Members',
      dataIndex: 'otherMember',
      key: 'otherMember',
      sorter: (a, b) => (a.otherMember.toLowerCase() < b.otherMember.toLowerCase() ? -1 : 1),
      sortOrder: sortedInfo.columnKey === 'otherMember' ? sortedInfo.order : null,
    },
    {
      title: 'Closed By',
      dataIndex: 'closedBy',
      key: 'closedBy',
      sorter: (a, b) => (a.closedBy.toLowerCase() < b.closedBy.toLowerCase() ? -1 : 1),
      sortOrder: sortedInfo.columnKey === 'closedBy' ? sortedInfo.order : null,
    },
    {
      title: 'Closed On',
      dataIndex: 'closedOn',
      key: 'closedOn',
      sorter: (a, b) => (a.closedOn.toLowerCase() < b.closedOn.toLowerCase() ? -1 : 1),
      sortOrder: sortedInfo.columnKey === 'closedOn' ? sortedInfo.order : null,
    },
    {
      title: 'Deletion Date',
      dataIndex: 'deletionDate',
      key: 'deletionDate',
      sorter: (a, b) => (a.deletionDate.toLowerCase() < b.deletionDate.toLowerCase() ? -1 : 1),
      sortOrder: sortedInfo.columnKey === 'deletionDate' ? sortedInfo.order : null,
    },
  ];

  const getProjects = async () => {
    const response = await DataService.get('/project/get-all-project');
    if (response.status == 200) {
      createItems(response.data.projects);
      createData(response.data.projects);
      const openProject = response.data.projects.filter((n) => !n.isClosed).length;
      const closeProject = response.data.projects.filter((n) => n.isClosed).length;
      setOpenProjects(openProject);
      setCloseProjects(closeProject);
      setProjects({
        loaded: true,
        data: response.data.projects,
      });
    }
  };

  const createData = (projects, value = 'all') => {
    const data = [];
    projects.map((item) => {
      if (
        value == 'all' ||
        (value == 'active' && item.isClosed == false) ||
        (value == 'close' && item.isClosed == true)
      ) {
        const tempItem = {
          key: item.id, 
          projectName: item.projectName,
          isClosed: item.isClosed,
          createdBy: item.account_user_projects === null ? '' : item.account_user_projects.fullName,
          createdOn: dayjs(item.createdAt).format(date_time_format.toUpperCase()),
          productOwner: item.project_members_all
            .filter((n) => n.userType == 'Owner') 
            .map((user) => user.account_user_project_access !== null ? user.account_user_project_access.fullName : '')
            .join(','),
          otherMember: item.project_members_all
            .filter((n) => n.userType != 'Owner')
            .map((user) => user.account_user_project_access !== null && user.account_user_project_access.fullName.trim() !== "" && user.account_user_project_access.fullName)
            .join(', ').split(", false").join(''),
          closedBy: item.isClosed && item.closed_user ? item.closed_user.fullName : '',
          closedOn: item.isClosed && item.ClosedAt ? dayjs(item.ClosedAt).format(date_time_format.toUpperCase()) : '',
          deletionDate: item.isClosed && item.deletedAt,
          userType: item.account_user_projects === null ? '' : item.account_user_projects.userTypeStatus,
        };
        data.push(tempItem);
      }
    });
    setTableData(data);
  };

  const createItems = (projects, value = 'all') => {
    const items = [];
    projects.map((item) => {
      if (
        value == 'all' ||
        (value == 'active' && item.isClosed == false) ||
        (value == 'close' && item.isClosed == true)
      ) {
        var randomColor = Math.floor(Math.random() * 16777215).toString(16);
        const tempItem = {
          key: 'item_' + item.id,
          title: item.projectName,
          color: '#' + randomColor,
          expanded: false,
          data: {
            startDate: item.project_option.projectStartDate ? item.project_option.projectStartDate : null,
            endDate: item.project_option.projectEndDate
              ? item.project_option.projectEndDate
              : item.project_option.projectStartDate
              ? item.project_option.projectStartDate
              : null,
          },
        };
        
        items.push(tempItem);
      }
    });
    setTimeline(items);
  };

  useEffect(() => {
    const retrieveData = async () => {
      await getProjects();
    };
    retrieveData();
  }, []);

  const onProjectChange = (e) => {
    if (e.target.checked)
    {
      setShowTooltip(false);
      setCreateNewActiveProject(true);
      setConfirmLoadingNoLoader(false);
    }
    else
    {
      setCreateNewActiveProject(false);
      setConfirmLoadingNoLoader(true);
      setButtonText("Proceed");
    }
    setCheckState({ ...checkState, checked: e.target.checked });
  }

  const createProject = async (project, addAudience) => {
    if (closedProjectId.length > 0)
    {
      await closeProject(closedProjectId);
      setClosedProjectId([]);
    }
    localStorage.setItem('active_project_id', project.id);
    localStorage.setItem('active_project_name', project.projectName);
    setIsCreateProjectModalVisible(false);
    await makeActiveProject({key:project.id}, addAudience, true);
    if (!addAudience)
    {
        window.location.reload();
    }
    else
    {
        openAudienceModal();
    }
  }

  const openAudienceModal = () => {
      setInitiateModalOpen(true);
      setReloadOnClose(true);
  }

  const makeActiveProject = async (item, addAudience, reload) => {
    
    let currentSelectedProject = item.key;
    const data = {
      project_id: currentSelectedProject,
    };

    const response = await DataService.post(
      "/users/setActiveProject/account_users",
      data
    );

    if (response.status === 200)
    {
        //Data Saved
        openSuccess('bottomRight', "Create Project",  `Create a new projects and marked as active`);
        if (!addAudience)
        {
            if (reload)
            {
              navigate(0);
            }
        }
        else
        {
            openAudienceModal();
        }
    }
    else
    {
      openError("topLeft", "Archieve Project Error", "Project can't be marked as active");
      await getProjects();
    }
  }

  return (
    <Main>
      {contextHolder}
      <ProjectsDiv>
        <Cards headless style={{ padding: '30px' }}>
          {projects.loaded === false || projects.data.length == 0 ? (
            <Row gutter={[25, 25]} style={{ marginTop: '30px' }}>
              <Col
                span={24}
                style={{ minHeight: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Skeleton avatar active paragraph={{ rows: 3 }} />
              </Col>
            </Row>
          ) : (
            <>
              <Row gutter={[50, 50]} style={{ marginTop: '20px' }}>
                <Col span={8}>
                  <Title level={3}>Your Projects</Title>
                  <div className="project-box">
                    <div
                      className="box-back-blue box"
                      onClick={() => changeShowProject('all')}
                      style={{
                        cursor:showProject != 'all'?'pointer':'crosshair',
                        opacity: showProject == 'all' ? '1' : '0.5',
                        boxShadow:
                          showProject == 'all'
                            ? '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'
                            : 'none',
                      }}
                    >
                      <Row>
                        <Col span={22}>Total Number of Projects</Col>
                        <Col span={2} className="underline">
                          {projects.data.length}
                        </Col>
                      </Row>
                    </div>
                    <div
                      className="box-back-orange box"
                      onClick={() => changeShowProject('active')}
                      style={{
                        cursor:showProject != 'active'?'pointer':'crosshair',
                        opacity: showProject == 'active' ? '1' : '0.5',
                        boxShadow:
                          showProject == 'active'
                            ? '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'
                            : 'none',
                      }}
                    >
                      <Row>
                        <Col span={22}>Active Projects</Col>
                        <Col span={2} className="underline">
                          {openProjects}
                        </Col>
                      </Row>
                    </div>
                    <div
                      className="box-back-gray box"
                      onClick={() => changeShowProject('close')}
                      style={{
                        cursor:showProject != 'close'?'pointer':'crosshair',
                        opacity: showProject == 'close' ? '1' : '0.5',
                        boxShadow:
                          showProject == 'close'
                            ? '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'
                            : 'none',
                      }}
                    >
                      <Row>
                        <Col span={22}>Archived Projects</Col>
                        <Col span={2} className="underline">
                          {closeProjects}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col span={16}>
                  <div className="border-rounded">
                    {timeline && (
                      <div style={{ height: '420px', width: '100%', marginTop: '0px' }}>
                        <Gantt>
                          <Title level={3} style={{width: "100%"}} underline>Project Timeline</Title>
                          <div style={{width: "100%", marginBottom: "20px"}}>
                            <p style={{fontSize: "16px"}}>Select a project</p>
                            <Select
                              value={timelineProject.projectName}
                              defaultValue={timelineProject.projectName}
                              onSelect={(value) => {
                                if (value === "all")
                                {
                                  changeShowProject('all');
                                  setTimelineProject({id: "all", projectName: "All Projects"});
                                }
                                else
                                {
                                  projects.data.map(project => {
                                    if (project.id === value)
                                    {
                                      setTimelineProject(project);
                                      createItems([project], "all");
                                    }
                                  });
                                }
                              }}
                              options={[{id: "all", projectName: "All Projects"}].concat(projects.data).map(item => {
                                  return {
                                    value: item.id,
                                    label: item.projectName
                                  };
                                })
                              }
                              style={{
                                width: "100%"
                              }}
                            />
                          </div>
                          <GlobalStyle />
                          <Gantt.Controls />
                          <Gantt.Chart data={timeline} />
                        </Gantt>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: '30px' }}>
                <Col span={24} style={{paddingTop: "20px"}}>
                  <Title level={4}>Projects</Title>
                </Col>
                <Col span={24} style={{paddingTop: "5px", paddingBottom: "20px"}}>
                  <Space wrap>
                    <Select
                      value={currentFilter}
                      style={{ width: 200 }}
                      onChange={(val) => {
                        changeShowProject(val)
                      }}
                      options={[
                        { value: 'all', label: 'All' },
                        { value: 'active', label: 'Active Projects' },
                        { value: 'close', label: 'Inactive Projects' }
                      ]}
                    />
                  </Space>
                </Col>
                <Col span={24}>
                  {tableData && (
                    <Table
                      onChange={handleChange}
                      columns={columns}
                      rowSelection={{
                        ...rowSelection,
                      }}
                      dataSource={tableData}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space wrap>
                    <Button 
                      type="ocm-default-dark-blue" onClick={reopenSelectedProject} size="large" disabled={selectedProject.length > 0 ? false : true}>
                      Re-Activate Project(s)
                    </Button>
                    <Button 
                      type="ocm-default-danger" 
                      size="large" 
                      danger 
                      disabled={selectedProject.length > 0 ? false : true}
                      onClick={() => {
                        if (selectedProject.length === 1)
                        {
                          var noOfProject = 0;
                          projects.data.map(project => {
                            noOfProject++;
                          });
                          projects.data.map(item => item.id === selectedProject[0] ? setCurrentSelectedProjectName(item.projectName) : "")
                          if (projects.data.filter(project => project.isClosed !== true).filter(project => !selectedProject.includes(project.id)).length > 0)
                          {
                            if (noOfProject > 1)
                            {
                              setCheckState({checked: true});
                            }
                          }
                          else if (projects.data.filter(project => project.isClosed !== true).filter(project => !selectedProject.includes(project.id)).length === 0)
                          {
                            setCheckState({checked: false});
                          }
                          setCreateNewActiveProject(false);
                          setConfirmLoadingNoLoader(true);
                          setShowTooltip(false);
                          setIsSingleModalVisible(true);
                          setCurrentSelectedProject(selectedProject[0]);
                        }
                        else
                        {
                          var noOfProject = 0;
                          projects.data.map(project => {
                            noOfProject++;
                          });
                          setCurrentSelectedProjectName(`All selected`)
                          setShowTooltip(false);
                          setIsModalVisible(true);
                          if (projects.data.filter(project => project.isClosed !== true).filter(project => !selectedProject.includes(project.id)).length > 0)
                          {
                            if (noOfProject > 1)
                            {
                              setCheckState({checked: true});
                            }
                          }
                          else if (projects.data.filter(project => project.isClosed !== true).filter(project => !selectedProject.includes(project.id)).length === 0)
                          {
                            setCheckState({checked: false});
                          }
                          projects.data.map(item => {
                            if (parseInt(item.id) === parseInt(localStorage.getItem('active_project_id')))
                            {
                              setConfirmLoadingNoLoader(true);
                            }
                          });
                          let isClosed = 0;
                          for(var ind in projects.data)
                          {
                            const selectedRow = projects.data[ind];
                            if (selectedProject.includes(selectedRow.id))
                            {
                              if (selectedRow.isClosed)
                              {
                                isClosed++;
                              }
                            }
                          }
                          if (parseInt(isClosed) === parseInt(selectedProject.length))
                          {
                            setConfirmLoadingProcced(true);
                          }
                          else
                          {
                            setConfirmLoadingProcced(false);
                          }
                        }
                      }}
                    >
                      Archive Project(s)
                    </Button>
                  </Space>
                </Col>
              </Row>
            </>
          )}
        </Cards>
      </ProjectsDiv>
      <OCMModal>
        {isModalVisible && (
          <Modal
            style={{
              margin: '20px 0px',
            }}
            className="add-change"
            title={
              <OCMModalHeader>
                <div className="user-info-header-panel">
                  {selectedProject.length === 1 ? (
                    <h1 className="header-title">Archive This Project</h1>
                  ) : (
                    <h1 className="header-title">Archive Seleted Project{selectedProject.length > 1 ? 's' : ''}</h1>
                  )}
                  <div className="ribon none"></div>
                </div>
              </OCMModalHeader>
            }
            centered
            closeIcon={''}
            maskClosable={false}
            keyboard
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={() => setIsModalVisible(false)}
            width={800}
            wrapClassName={'ocm-modal'}
            footer={[
              <Button
                key="cancel"
                onClick={() => setIsModalVisible(false)}
                size="large"
                style={{
                  fontSize: '18px', // Increase font size
                  padding: '0px 24px', // Increase padding
                  height: 'auto', // Set height
                  lineHeight: '50px', // Center the text vertically
                }}
                className={'projectCancelButton'}
              >
                Cancel
              </Button>,
              <Button
                key="archive"
                type="ocm-default"
                onClick={async() => {
                  if (!checkState.checked)
                  {
                    console.log("golla",checkState)
                    setShowTooltip(true);
                  }
                  else
                  {
                    setConfirmLoading(true);
                    let projectCount = 0;
                    tableData.map(item => {
                      if (selectedProject.includes(item.key))
                      {
                        if (item.isClosed === false)
                        {
                            projectCount++;
                        }
                      }
                    });
                    
                    if (projectCount > 0)
                    {
                      if (activateProject)
                      {
                        await makeCurrentActive(newSelectedProject);
                      }
                      closeSelectedProject();
                    }
                    else
                    {
                      setIsModalVisible(false);
                      setConfirmLoading(false);
                      if (selectedProject.length === 1)
                      {
                        openError("topLeft", "Archieve Project Error", "The Project you try to archieve, is already marked as archived")
                      }
                      else
                      {
                        openError("topLeft", "Archieve Project Error", "The Projects you try to archieve, are already marked as archived")
                      }
                    }
                  }
                }}
                icon={confirmLoadingProcced ? confirmLoading ? <Spin /> : <FontAwesomeIcon icon={regular("check")} /> : <FontAwesomeIcon icon={regular("check")} />}
                size="large"
                disabled={confirmLoadingProcced}
                style={{
                  fontSize: '18px', // Increase font size
                  padding: '0px 24px', // Increase padding
                  height: 'auto', // Set height
                  lineHeight: '50px', // Center the text vertically
                }}
                className={'projectOkButton'}
              >
                {buttonText}
              </Button>,
            ]}
          >
            {hasActiveOnSelectedProject && (
              <div className='activeProjectError' style={{width: "100%", padding: "20px 40px"}}>
                {projects.data.length === selectedProject.length ? (
                  <Alert message={`There should be at least one Active Project!`} type="warning" showIcon={false} closable={false} />
                ) : (
                  <Alert message="You're trying to archive the project you're working in." type="warning" showIcon={false} closable={false} />
                )}
                
                <p style={{padding: "10px", marginTop: "10px"}}>
                    {projects.data.filter(project => project.isClosed !== true).length > 1 ? (
                      <div style={{textAlign: "left", position: "relative"}}>
                        <Tooltip
                          title="Click checkbox to agree before you proceed" 
                          color={"#F00"}
                          open={showTooltip}
                          overlayClassName={'tooltip-error'}
                          placement={'topLeft'}
                          overlayInnerStyle={{
                            color: "#fff",
                            fontSize: "24px",
                            lineHeight: "36px",
                            borderRadius: "15px",
                            width: "300px",
                            marginLeft: "-100px",
                            padding: "10px 30px"
                          }}
                          overlayStyle={{
                            borderRadius: "15px"
                          }}
                        >
                          <Checkbox onChange={onProjectChange} checked={checkState.checked}>
                            <p style={{marginBottom: 3}}>Mark "<b>{currentSelectedProjectName}</b>" project close and create a new project</p>
                          </Checkbox>
                        </Tooltip>
                      </div>
                    ) : (
                        projects.data.filter(project => project.isClosed !== true).filter(project => !selectedProject.includes(project.id)).length === 0 ? (
                          <div style={{textAlign: "left", position: "relative"}}>
                            <Tooltip
                              title="Click checkbox to agree before you proceed" 
                              color={"#F00"}
                              open={showTooltip}
                              overlayClassName={'tooltip-error'}
                              placement={'topLeft'}
                              overlayInnerStyle={{
                                color: "#fff",
                                fontSize: "24px",
                                lineHeight: "36px",
                                borderRadius: "15px",
                                width: "300px",
                                marginLeft: "-100px",
                                padding: "10px 30px",
                                position: "absolute"
                              }}
                              overlayStyle={{
                                borderRadius: "15px"
                              }}
                            >
                              <Checkbox onChange={onProjectChange} checked={checkState.checked}>
                                <p style={{marginBottom: 3}}>Mark "<b>{currentSelectedProjectName}</b>" project close and create a new project</p>
                              </Checkbox>
                            </Tooltip>
                          </div>
                        ) : (
                          <div style={{padding: "20px", width: "100%"}}>
                            <p style={{fontSize: "16px"}}>Choose a project to switch into</p>
                            <Select
                              value={newSelectedProject.projectName}
                              defaultValue={newSelectedProject.projectName}
                              onSelect={(value) => {
                                projects.data.map(project => {
                                  if (project.id === value)
                                  {
                                    setNewSelectedProject(project);
                                    setActivateProject(true);
                                    setConfirmLoadingNoLoader(false);    
                                  }
                                });
                              }}
                              options={projects.data.filter(project => project.isClosed !== true).filter(project => !selectedProject.includes(project.id)).map(item => {
                                  return {
                                    value: item.id,
                                    label: `${item.projectName} ${parseInt(item.id) === parseInt(localStorage.getItem('active_project_id')) ? '(Current Active Project)' : ''}`,
                                    disabled: parseInt(item.id) === parseInt(localStorage.getItem('active_project_id')),
                                    className: parseInt(item.id) === parseInt(localStorage.getItem('active_project_id')) ? 'activeBG' : ''
                                  };
                                })
                              }
                              style={{
                                width: "100%"
                              }}
                            />
                          </div>
                        )
                    )}
                </p>
              </div>
            )}
            {!hasActiveOnSelectedProject && (
              <div style={{width: "100%", padding: "20px"}}>
                <p style={{marginBottom: "0"}}><Text type='warning' strong style={{fontSize: "30px"}}>Warning</Text></p>
                <p style={{marginBottom: "20px"}}>
                  <Text style={{fontSize: "23px", paddingBottom: "20px"}} italic>You are about to archive {selectedProject.length} project{selectedProject.length > 1 ? 's' : ''}. Are you sure you want to proceed?</Text>
                </p>
              </div>
            )}
          </Modal>
        )}
        {isSingleModalVisible && (
          <Modal
            style={{
              margin: '20px 0px',
            }}
            className="add-change"
            title={
              <OCMModalHeader>
                <div className="user-info-header-panel">
                  <h1 className="header-title">Archive This Project</h1>
                  <div className="ribon none"></div>
                </div>
              </OCMModalHeader>
            }
            centered
            closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
            open={isSingleModalVisible}
            onCancel={() => {
              setConfirmLoadingProcced(false);
              setIsSingleModalVisible(false);
              setCurrentSelectedProject(0);
              setCurrentSelectedProjectName("");
              setNewSelectedProject([]);
              setActivateProject(false);
            }}
            onOk={() => {
              setConfirmLoadingProcced(false);
              setIsSingleModalVisible(false);
              setCurrentSelectedProject(0);
              setCurrentSelectedProjectName("");
              setNewSelectedProject([]);
              setActivateProject(false);
            }}
            width={800}
            wrapClassName={'ocm-modal'}
            footer={[
              <Button
                key="cancel"
                onClick={() => {
                  setIsSingleModalVisible(false);
                  setCurrentSelectedProject(0);
                  setCurrentSelectedProjectName("");
                  setNewSelectedProject([]);
                  setActivateProject(false);
                }}
                size="large"
                style={{
                  fontSize: '18px', // Increase font size
                  padding: '0px 24px', // Increase padding
                  height: 'auto', // Set height
                  lineHeight: '50px', // Center the text vertically
                }}
                className={'projectCancelButton'}
              >
                Cancel
              </Button>,
              <Button
                key="archive"
                type="ocm-default"
                onClick={async () => {
                  if (!checkState.checked)
                  {
                    setShowTooltip(true);
                  }
                  else
                  {
                    setConfirmLoading(true);
                    if (currentSelectedProject !== 0)
                    {
                      var noOfProject = 0;
                      projects.data.map(project => {
                        noOfProject++;
                      });
                      if (activateProject)
                      {
                        await makeCurrentActive(newSelectedProject);
                      }
                      if (noOfProject > 1)
                      {
                        if (projects.data.filter(project => project.isClosed !== true).length === 1)
                        {
                          let data = [];
                          data.push(currentSelectedProject);
                          setClosedProjectId(data);
                          setIsModalVisible(false);
                          setIsSingleModalVisible(false);
                          setIsCreateProjectModalVisible(true);
                        }
                        else
                        {
                          let data = [];
                          data.push(currentSelectedProject);
                          closeProject(data);
                        }
                      }
                      else if (noOfProject === 1)
                      {
                        let data = [];
                        data.push(currentSelectedProject);
                        setClosedProjectId(data);
                        setIsModalVisible(false);
                        setIsSingleModalVisible(false);
                        setIsCreateProjectModalVisible(true);
                      }
                    }
                    else
                    {
                      setIsSingleModalVisible(false);
                      setConfirmLoading(false);
                      setCurrentSelectedProject(0);
                      setCurrentSelectedProjectName("");
                      setNewSelectedProject([]);
                      setActivateProject(false);
                      openError("topLeft", "Archieve Project Error", "The Project you try to archieve, is already marked as archived")
                    }
                  }
                }}
                icon={confirmLoadingProcced ? confirmLoading ? <Spin /> : <FontAwesomeIcon icon={regular("check")} /> : <FontAwesomeIcon icon={regular("check")} />}
                size="large"
                disabled={confirmLoadingProcced}
                style={{
                  fontSize: '18px', // Increase font size
                  padding: '0px 24px', // Increase padding
                  height: 'auto', // Set height
                  lineHeight: '50px', // Center the text vertically
                }}
                className={'projectOkButton'}
              >
                {buttonText}
              </Button>,
            ]}
          >
            {hasActiveOnSelectedProject && (
              <div className='activeProjectError' style={{width: "100%", padding: "20px 40px"}}>
                {projects.data.filter(project => project.isClosed !== true).length > 1 ? (
                  <Alert message="You're trying to archive the project you're working in." type="warning" showIcon={false} closable={false} />
                ) : (
                  <Alert message="There should be at least one Active Project!" type="warning" showIcon={false} closable={false} />
                )}
                <p style={{padding: "10px", marginTop: "10px"}}>
                    {projects.data.filter(project => project.isClosed !== true).length === 1 ? (
                      <div style={{textAlign: "left", position: "relative"}}>
                        <Tooltip
                          title="Click checkbox to agree before you proceed" 
                          color={"#F00"}
                          open={showTooltip}
                          overlayClassName={'tooltip-error'}
                          placement={'topLeft'}
                          overlayInnerStyle={{
                            color: "#fff",
                            fontSize: "24px",
                            lineHeight: "36px",
                            borderRadius: "15px",
                            width: "300px",
                            marginLeft: "-100px",
                            padding: "10px 30px"
                          }}
                          overlayStyle={{
                            borderRadius: "15px"
                          }}
                        >
                          <Checkbox onChange={onProjectChange} checked={checkState.checked}>
                            <p style={{marginBottom: 3}}>Mark "<b>{currentSelectedProjectName}</b>" project close and create a new project</p>
                          </Checkbox>
                        </Tooltip>
                      </div>
                    ) : (
                        projects.data.filter(project => project.isClosed !== true).filter(project => !selectedProject.includes(project.id)).length === 0 ? (
                          <div style={{textAlign: "left", position: "relative"}}>
                            <Tooltip
                              title="Click checkbox to agree before you proceed" 
                              color={"#F00"}
                              open={showTooltip}
                              overlayClassName={'tooltip-error'}
                              placement={'topLeft'}
                              overlayInnerStyle={{
                                color: "#fff",
                                fontSize: "24px",
                                lineHeight: "36px",
                                borderRadius: "15px",
                                width: "300px",
                                marginLeft: "-100px",
                                padding: "10px 30px"
                              }}
                              overlayStyle={{
                                borderRadius: "15px"
                              }}
                            >
                              <Checkbox onChange={onProjectChange} checked={checkState.checked}>
                                <p style={{marginBottom: 3}}>Mark "<b>{currentSelectedProjectName}</b>" project close and create a new project</p>
                              </Checkbox>
                            </Tooltip>
                          </div>
                        ) : (
                          <div style={{padding: "20px", width: "100%"}}>
                            <p style={{fontSize: "16px"}}>Choose a project to switch into</p>
                            <Select
                              value={newSelectedProject.projectName}
                              defaultValue={newSelectedProject.projectName}
                              onSelect={(value) => {
                                projects.data.map(project => {
                                  if (project.id === value)
                                  {
                                    setNewSelectedProject(project);
                                    setActivateProject(true);
                                    setConfirmLoadingNoLoader(false);    
                                  }
                                });
                              }}
                              options={projects.data.filter(project => project.isClosed !== true).map(item => {
                                  return {
                                    value: item.id,
                                    label: `${item.projectName} ${parseInt(item.id) === parseInt(localStorage.getItem('active_project_id')) ? '(Current Active Project)' : ''}`,
                                    disabled: parseInt(item.id) === parseInt(localStorage.getItem('active_project_id')),
                                    className: parseInt(item.id) === parseInt(localStorage.getItem('active_project_id')) ? 'activeBG' : ''
                                  };
                                })
                              }
                              style={{
                                width: "100%"
                              }}
                            />
                          </div>
                        )
                    )}
                </p>
              </div>
            )}
            {!hasActiveOnSelectedProject && (
              <div style={{width: "100%", padding: "20px"}}>
                <p style={{marginBottom: "0"}}><Text type='warning' strong style={{fontSize: "30px"}}>Warning</Text></p>
                <p style={{marginBottom: "20px"}}>
                  <Text style={{fontSize: "23px", paddingBottom: "20px"}} italic>You are about to archive {currentSelectedProject !== 0 ? 1 : 0} project. Are you sure you want to proceed?</Text>
                </p>
              </div>
            )}
          </Modal>
        )}
      </OCMModal>

      <Modal
          title=""
          wrapClassName={'ocm-modal'}
          open={isCreateProjectModalVisible}
          onCancel={() => setIsCreateProjectModalVisible(false)}
          width={1000}
          zIndex={99999}
          footer={[]}
          closable={false}
          maskClosable={false}
        >
        <AddNewProject createProject={(project, addAudience) => createProject(project, addAudience)}/>
      </Modal>
      {
          initiateModalOpen && (
            <AddAudienceModel 
                addTargetAudienceModalOpen={addTargetAudienceModalOpen}
                setAddTargetAudienceModalOpen={(val) => setAddTargetAudienceModalOpen(val)}
                initiateModalOpen={initiateModalOpen}
                setInitiateModalOpen={(val) => setInitiateModalOpen(val)}
                form={form}
            />
        )
      }
    </Main>
  );
}
export default ProjectManagement;
