import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  userProjectBegin,
  userProjectSuccess,
  userProjectErr,

  getAccountUserProjectsBegin,
  getAccountUserProjectsSuccess,
  getAccountUserProjectsErr,

  getProjectAssessmentBegin,
  getProjectAssessmentSuccess,
  getProjectAssessmentErr,

  saveProjectAssessmentBegin,
  saveProjectAssessmentSuccess,
  saveProjectAssessmentErr,

  setCloseProjectBegin,
  setCloseProjectSuccess,
  setCloseProjectErr,

  setReopenProjectBegin,
  setReopenProjectSuccess,
  setReopenProjectErr,
} = actions;

const getUserProjects = (callback) => {
  return async (dispatch) => {
    dispatch(userProjectBegin());
    try {
      const response = await DataService.get('/defaultData/userprojects');
      if (response.status !== 200) {
        const messages = dispatch(userProjectErr(response.data.message));
        callback(messages);
      } else if (response.status === 200) {
        const user_data_object = response.data._doc;
        const account_data = JSON.parse(user_data_object.userData);

        dispatch(userProjectSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(userProjectErr(err));
      callback(messages);
    }
  };
};

const getAccountUserProjects = (callback) => {
  return async (dispatch) => {
    dispatch(getAccountUserProjectsBegin());
    try {
      const response = await DataService.get('/project/get-user-projects');
      if (response.status !== 200) {
        const messages = dispatch(getAccountUserProjectsErr(response.data.message));
        callback(messages);
      } else if (response.status === 200) {
        dispatch(getAccountUserProjectsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getAccountUserProjectsErr(err));
      callback(messages);
    }
  };
};

const setCloseProject = (values, callback) => {
  return async (dispatch) => {
    dispatch(setCloseProjectBegin());
    try {
      const response = await DataService.post('/project/set-close-project', values);
      if (response.status !== 200) {
        const messages = dispatch(setCloseProjectErr(response.data.message));
        callback(messages);
      } else if (response.status === 200) {
        dispatch(setCloseProjectSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(setCloseProjectErr(err));
      callback(messages);
    }
  };
};

const setReopenProject = (values, callback) => {
  return async (dispatch) => {
    dispatch(setReopenProjectBegin());
    try {
      const response = await DataService.post('/project/set-reopen-project', values);
      if (response.status !== 200) {
        const messages = dispatch(setReopenProjectErr(response.data.message));
        callback(messages);
      } else if (response.status === 200) {
        dispatch(setReopenProjectSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(setCloseProjectErr(err));
      callback(messages);
    }
  };
};

const getProjectAssessment = (callback) => {
  return async (dispatch) => {
    dispatch(getProjectAssessmentBegin());
    try {
      const response = await DataService.get('/project/get-project-assessment');
      if (response.status !== 200) {
        const messages = dispatch(getProjectAssessmentErr(response.data.message));
        callback(messages);
      } else if (response.status === 200) {
        dispatch(getProjectAssessmentSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getProjectAssessmentErr(err));
      callback(messages);
    }
  };
};

const saveProjectAssessment = (values, callback) => {
  return async (dispatch) => {
    dispatch(saveProjectAssessmentBegin());
    try {
      const response = await DataService.post('/project/save-project-assessment', values);
      if (response.status !== 200) {
        const messages = dispatch(saveProjectAssessmentErr(response.data.message));
        callback(messages);
      } else if (response.status === 200) {
        dispatch(saveProjectAssessmentSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveProjectAssessmentErr(err));
      callback(messages);
    }
  };
};

export {
  getUserProjects,
  getAccountUserProjects,
  getProjectAssessment,
  setCloseProject,
  setReopenProject,
  saveProjectAssessment,
};
