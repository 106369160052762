import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Dropdown, Space, Divider, Button, Modal, Form, Menu, Input } from 'antd';

import { DataService } from "../../config/dataService/dataService";
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ProjectMenu } from '../Style';
import { clone } from 'lodash'

import './style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import AddNewProject from './AddNewProject';
import AddAudienceModel from '../../container/tools/panel/widgets/target_audiences/AddAudienceModel';
import { useDispatch } from 'react-redux';
import { getAccountUserProjects } from '../../redux/project/actionCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faPencil } from '@fortawesome/pro-duotone-svg-icons';
import { truncateString } from '../../utility/utility';
import { logOut } from '../../redux/authentication/actionCreator';

const { theme } = require('../../config/theme/themeVariables');

//const { useToken } = theme;

const GlobalStyle = createGlobalStyle`
  body {
    .ant-modal .ant-modal-close
      {
        background: white;
        border-radius: 50%;
        border: 1px solid black;
        width: 32px;
        height: 32px;
      }
  }
`;


const ProjectDropdown = ({UserInfo, axiosCallback}) => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const location = useLocation()
    const userTheme = JSON.parse(localStorage.getItem('userTheme'));
    theme['user-theme'] = userTheme;
    const [addTargetAudienceModalOpen, setAddTargetAudienceModalOpen] = useState(false);
    const [initiateModalOpen, setInitiateModalOpen] = useState(false);
    const [reloadOnClose, setReloadOnClose] = useState(false);
    const [items, setItems] = useState(false);
    const [projectData, setProjectData] = useState({
      loaded: false,
      projects: [],
      userType: 0
    });

    const [currentActiveName, setCurrentActiveName] = useState(truncateString(localStorage.getItem('active_project_name'), 25));
    const [activeName, setActiveName] = useState(localStorage.getItem('active_project_name'));
    const [currentActiveProjectId, setCurrentActiveProjectId] = useState(localStorage.getItem('active_project_id'));
    const [newActiveProjectName, setNewActiveProjectName] = useState('')
    const [isEditable, setIsEditable] = useState(false)
    const [open, setOpen] = useState(false)

    const organizationalSlug = localStorage.getItem('organizationalSlug');
    let projectName = localStorage.getItem('active_project_name');
    let projectFullName = "";
    let setCurrentProjectName = false;

    const initialState = {
        error: false,
        messages: ""
    };

    const reducer = (state, action) => {
        switch (action.type) {
          case 'GET_ACCOUNT_USER_PROJECT_ERR':
            return { ...state, error: true,messages: action.err };
          case 'RESET':
            return state;
          default:
            return state;
        }
    };

    const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

    const dispatch = useDispatch();
    
    const fetchData = useCallback(async () => {
      apiResponseCallback({ type: 'RESET' });
      dispatch(
          getAccountUserProjects((response) => {
            if (response.status === 200) {
              const projects = response.data.projects;
              if (projects.length === 0)
              {
                dispatch(logOut(() => navigate('/')));
              }
              const userType = response.data.userType;
              const projectInfo = [];
              const currentActiveProjectId = response.data.activeProjectId;
              let found = false;
              projects.map((project, i) => {
                if (currentActiveProjectId == project.id)
                {
                  setCurrentActiveName(truncateString(project.projectName, 25));
                  setActiveName(project.projectName);
                  setCurrentActiveProjectId(project.id);
                  found = true;
                }
                let tmp = {};
                tmp = {
                  key: project.id,
                  label: project.projectName,
                  projectname: project.projectName
                };
                projectInfo.push(tmp);
              });
              if (!found)
              {
                setCurrentActiveName(truncateString(projectInfo[0].label, 25));
                setActiveName(projectInfo[0].label);
                setCurrentActiveProjectId(projectInfo[0].key);
              }
              setProjectData({
                loaded: true,
                projects: projectInfo,
                userType: userType
              });
            } 
            else 
            {
              setProjectData({
                loaded: false,
                projects: [],
                userType: 0
              });
              apiResponseCallback(response);
            }
          }),
        );
    }, [dispatch, projectData, currentActiveName, activeName, currentActiveProjectId]);

    const updateProjectName = async () => {
      const response = await DataService.post(
        "/project/update-project-name",
        {
          projectName: newActiveProjectName,
          projectId: currentActiveProjectId,
        }
      );

      if (response.status === 200){
        const projects = clone(projectData.projects)
        const index = projects.findIndex(project => project.key === currentActiveProjectId);
        if(index !== -1) {
          projects[index] = {
            ...projects[index],
            label: newActiveProjectName,
            projectname: newActiveProjectName
          }
          setProjectData({
            ...projectData,
            projects
          })
        }
        localStorage.setItem('active_project_name', newActiveProjectName);
        setCurrentActiveName(truncateString(newActiveProjectName, 25));
        setActiveName(newActiveProjectName);
        setNewActiveProjectName('');
        setIsEditable(false);
      }
    }
    
    useEffect(() => {
      const retrieveData = async () => {
          fetchData();
      }
      retrieveData().catch(console.error);
    }, []);

    window.addEventListener('resize', updateText);
    function updateText() 
    {
        let tmpActiveName = activeName;
        if (window.innerWidth > 600 && window.innerWidth <= 991)
        {
          tmpActiveName = truncateString(tmpActiveName, 20);
        }
        else if (window.innerWidth > 450 && window.innerWidth <= 600)
        {
          tmpActiveName = truncateString(tmpActiveName, 15);
        }
        else if (window.innerWidth <= 450)
        {
          tmpActiveName = truncateString(tmpActiveName, 10);
        }
        else if (tmpActiveName.length > 25)
        {
          tmpActiveName = truncateString(tmpActiveName, 25);
        }
        setCurrentActiveName(tmpActiveName);
    }

    const createProject = async (project, addAudience) => {
      setCurrentActiveName(truncateString(project.projectName, 25));
      setActiveName(project.projectName);
      setCurrentActiveProjectId(project.id);
      setCurrentActiveProjectId(project.id);
      localStorage.setItem('active_project_id', project.id);
      localStorage.setItem('active_project_name', project.projectName);
      setIsModalVisible(false);
      await makeActive({key:project.id}, addAudience, false);
      if (!addAudience)
      {
          window.location.reload();
      }
      else
      {
          openAudienceModal();
      }
    }

    const openAudienceModal = () => {
        setInitiateModalOpen(true);
        setReloadOnClose(true);
    }

    const makeActive = async (item, addAudience, reload) => {
      
      let currentSelectedProject = item.key;
      const data = {
        project_id: currentSelectedProject,
      };

      const response = await DataService.post(
        "/users/setActiveProject/account_users",
        data
      );

      if (response.status === 200)
      {
          //Data Saved
          axiosCallback({
            ...UserInfo, 
            isSuccess: true, 
            successType: "Active Project Change",
            successMessage: "Change Project successfully"
          });
          let projectName = "";
          let projectId = "";
          projectData.projects.map(project => {
            if (parseInt(project.key) === parseInt(item.key))
            {
              projectName = project.label;
              projectId = project.key;
            }
          })

          if(projectName && projectId) {
            setCurrentActiveName(truncateString(projectName, 25));
            setActiveName(projectName);
            localStorage.setItem('active_project_name', projectName);
            localStorage.setItem('active_project_id', projectId);   
          }
          
          const locations = location.pathname.split("/");
          if (locations[locations.length - 1] === "tasks-checklist")
          {
            //navigate(0);
          }
          //Lets Reload the page for now
          //
          if (!addAudience)
          {
              if (reload)
              {
                navigate(0);
              }
          }
          else
          {
              openAudienceModal();
          }
      }
      else
      {
        axiosCallback({
          ...UserInfo, 
          isError: true, 
          successType: "Active Project Change",
          errorMessage: response.message
        });
        let nameOfProject = "";
        for(var i in items)
        {
          if (parseInt(items[i].key) === parseInt(item.key))
          {
            nameOfProject = items[i].projectname;
          }
        }
        setCurrentActiveName(truncateString(nameOfProject, 25));
        //alert(response.message);
      }
    }

    const contentStyle = {
        backgroundColor: userTheme.backgroundColor,
        borderRadius: "0px",
        color: userTheme.Color
    };

    const menuStyle = {
        boxShadow: 'none',
        borderRadius: "0px"
    };

    const dropdown = (menu) => {
      return (
        <Menu>
          {menu.props.items.map((item) => (
            <Menu.Item
              key={item.key}
              onClick={(project) =>
                item.key !== currentActiveProjectId
                  ? makeActive(project, false, true)
                  : null
              }
            >
              {currentActiveProjectId === item.key ? (
                <>
                {
                  !isEditable ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ marginRight: 'auto' }}>{item.label}</span>
                    {currentActiveProjectId === item.key && (
                      <FontAwesomeIcon icon={faPencil} onClick={() => {
                        setIsEditable(true)
                        setNewActiveProjectName(item.projectname)
                      }} />
                    )}
                  </div>) : (
                    <div style={{    display: 'flex',
                      width: '208px',
                      justifyContent: 'space-between'}} >
                      <Input
                        value={newActiveProjectName}
                        initialState={currentActiveName}
                        style={{width: '65%'}}
                        onChange={(e) => setNewActiveProjectName(e.target.value)}
                      />
                      <span style={{width: '60px'}} >
                        <Button onClick={updateProjectName}>Save</Button>
                      </span>
                    </div>
                  )
                }
                </>) : ( 
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ marginRight: 'auto' }}>{item.label}</span>
                  </div>
                )}
            </Menu.Item>
          ))}
        </Menu>
      );
    };
    
    const is_amazon = localStorage.getItem('is_amazon') === 'true';

    return (
        <ThemeProvider theme={theme}>
          <ProjectMenu>
            {!projectData.loaded ? (
                <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, "padding": "20px 10px"}} />
            ) : (
              <Dropdown
                open={open}
                onOpenChange={() => setOpen(!open)}
                menu={{ 
                  items: projectData.projects,
                  multiple: false,
                  selectable: true,
                  defaultSelectedKeys: [currentActiveProjectId.toString()],
                  onClick: (project) => {
                    makeActive(project, false, true);
                  }
                }}
                overlayClassName='project-dropdown'
                dropdownRender={(menu) => (
                  <div style={contentStyle}>
                    {React.cloneElement(dropdown(menu), { style: menuStyle })}
                    <Divider style={{ margin: 0 }} />
                    {parseInt(projectData.userType) < 3 ? (
                      <Space className='buttonAddNew' style={{ padding: 18, display: "flex", justifyContent: "center" }}>
                          <Button type="ocm-default" onClick={()=>{setIsModalVisible(true)}}><FontAwesomeIcon icon={regular("plus")} /> Add New Project</Button>
                      </Space>
                    ) : (
                      parseInt(projectData.userType) <= 3 && is_amazon && (
                        <Space className='buttonAddNew' style={{ padding: 18, display: "flex", justifyContent: "center" }}>
                            <Button type="ocm-default" onClick={()=>{setIsModalVisible(true)}}><FontAwesomeIcon icon={regular("plus")} /> Add New Project</Button>
                        </Space>
                      )
                    )}
                  </div>
                )} 
              >
              <a onClick={(e) => e.preventDefault()}>
                  <Space style={{color: userTheme.Color}}>
                    {`${currentActiveName}`}
                  </Space>
                  <FontAwesomeIcon icon={duotone("caret-down")} style={{"--fa-primary-color": "#FFFFFF", "--fa-secondary-color": "#FFFFFF", position: "absolute", top: "35%", right: "10px"}} />
              </a>
              </Dropdown>
            )}
            <Modal
              title=""
              wrapClassName={'ocm-modal'}
              open={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              width={1000}
              zIndex={99999}
              footer={[]}
            >
            <AddNewProject createProject={(project, addAudience) => createProject(project, addAudience)}/>
          </Modal>
          {
                initiateModalOpen && (
                  <AddAudienceModel 
                      addTargetAudienceModalOpen={addTargetAudienceModalOpen}
                      setAddTargetAudienceModalOpen={(val) => setAddTargetAudienceModalOpen(val)}
                      initiateModalOpen={initiateModalOpen}
                      setInitiateModalOpen={(val) => setInitiateModalOpen(val)}
                      form={form}
                  />
              )
            }
          </ProjectMenu>
         
        </ThemeProvider>
    );
};

export default ProjectDropdown;
