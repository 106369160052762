import React, { useState, useEffect, Suspense, useReducer, useCallback } from 'react';
import { Row, Col, Button, Skeleton, Modal, Tabs, Table, Space, Avatar, Typography, Alert } from 'antd';
const {Title, Text} = Typography;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons';
import { UserStyle } from './Style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { DataService } from '../../config/dataService/dataService';
import { OCMModal, OCMModalHeader } from '../tools/Style';
import '../tools/panel/widgets/style.css';
import CreateUserModal from './CreateUserModal';
import { useDispatch } from 'react-redux';
import { verifyUser } from '../../redux/authentication/actionCreator';
import { Link, useNavigate } from 'react-router-dom';
import AccountUserProfileAvatar from '../tools/panel/widgets/AccountUserProfileAvatar';
import useNotificationController from '../../utility/useNotificationController';

const toolsTab = [
  {
    tab_name: 'User Management',
    tab_key: 'team-members',
    tab_index: '1',
  },
];
const icons = ['faUsers', 'faFilePlus', 'faFolderPlus', 'faNotebook'];
icons.map((icon, i) => {
  library.add(ProIcons[icon]);
});
function UserManagement(props) {
  const {
    contextHolder,
    openError,
    openSuccess
  } = useNotificationController();

  const navigate = useNavigate();
  
  const organizationalSlug = localStorage.getItem('organizationalSlug')
  const [accountInfo, setAccountInfo] = useState();
  const [accountUserData, setAccountUserData] = useState({
    loaded: false,
    accountUsers: [],
    deletedAccountUsers: []
  });
  const [initialFilters, setInitialFilters] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [userData, setUserData] = useState();
  const [totalUser, setTotalUser] = useState(0);
  const [hasUserLicenses, setHasUserLicenses] = useState(true);
  const [hasGuestLicenses, setHasGuestLicenses] = useState(true);

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const getRandomColor = () => {
    const letters = '0123456789ABC';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  const RandomColorAvatar = ({ text, record }) => {
    const randomColor = getRandomColor();
    return (
      <>
        <Avatar size={44} style={{ backgroundColor: randomColor }}>
          {text.slice(0, 2)}
        </Avatar>
        <span style={{ marginLeft: '10px' }}>{text}</span>
      </>
    );
  };

  const handleSave = async () => {
    await getUsers();
    await getAccountInfo();
  };

  const cancelData = () => {
    setUserData();
  };

  const showModal = (e) => {
    if (!hasUserLicenses && !hasGuestLicenses)
    {
      scrollToElement(e, "consumed_licenses");
    }
    else if (hasUserLicenses || hasGuestLicenses)
    {
      setIsModalEditVisible(true);
    }
  };

  const scrollToElement = (e, target) => {
      e.preventDefault();
      e.stopPropagation();
      window.scrollTo({
          behavior: 'smooth',
          top:
            document.querySelector(`#${target}`).getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            100,
      });
      // document.querySelector(`#${target}`).scrollIntoView({
      //     block: "start", 
      //     behavior: "smooth"
      // });
  }

  const showDeleteModal = (record) => {
    setToBeDeleted(record);
    setIsModalVisible(true);
  };

  const editRecord = (value) => {
    setUserData(value);
    setIsModalEditVisible(true);
  };

  const handleDelete = async () => {
    const response = await DataService.post('/users/delete-account-user', { id: toBeDeleted.id });
    if (response.status == 200) {
      setIsModalVisible(false);
      if (parseInt(response.data.remainingUser) === 0)
      {
        setHasUserLicenses(false);
      }
      else if (parseInt(response.data.remainingUser) > 0)
      {
        setHasUserLicenses(true);
      }
      
      if (parseInt(response.data.remainingGuest) === 0)
      {
        setHasGuestLicenses(false);
      }
      else if (parseInt(response.data.remainingGuest) > 0)
      {
        setHasGuestLicenses(true);
      }
      openSuccess('bottomRight', "User Management",  `Account user deleted successfully`);
      await getUsers();
      await getAccountInfo();
    }
    setToBeDeleted(null);
  };

  const getUniqueProjects = (projects) => {
    if (!projects) return [];

    const uniqueProjectsMap = {};

    projects.forEach((val) => {
      const projectName = val.project_access?.projectName;
      const projectId = val.project_access?.id;

      if (projectId && projectName && !uniqueProjectsMap[projectId]) {
        uniqueProjectsMap[projectId] = projectName;
      }
    });

    return Object.keys(uniqueProjectsMap).map((id) => {
      return {
        text: uniqueProjectsMap[id],
        value: id,
      };
    });
  };

  const getAccountInfo = async () => {
    const response = await DataService.get('/users/get-account-information');
    if (response.status == 200) {
      const accountData = response.data.account;
      setAccountInfo(accountData);
      setTotalUser(accountData.account_users.length);
    }
  };

  const getUsers = async () => {
    const response = await DataService.get('/users/get-account-all-user');
    if (response.status == 200) {
      let accountUsers = response.data.accountUsers;
      let deletedAccountUsers = response.data.deletedAccountUsers;
      accountUsers = accountUsers.map(item => {return {...item, 'key': item.id}})
      deletedAccountUsers = deletedAccountUsers.map(item => {return {...item, 'key': item.id}})
      setAccountUserData({
        loaded: true,
        accountUsers: accountUsers,
        deletedAccountUsers: deletedAccountUsers,
      });
      const filters = {};
      accountUsers.forEach((item) => {
        Object.keys(item).forEach((key) => {
          filters[key] = Array.from(new Set((filters[key] || []).concat(item[key])));
        });
      });

      setInitialFilters(filters);
    }
  };

  const columns = [
    {
      key: 'user-fullname',
      title: 'User',
      dataIndex: 'fullName',
      filteredValue: filteredInfo.fullName || null,
      // render: (text, record) => <RandomColorAvatar text={text} record={record} />,
      render: (text, record) => <><AccountUserProfileAvatar {...props} audience={record} /><span style={{ marginLeft: '10px' }}>{text}</span></>,
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      filters: initialFilters.fullName?.map((val) => ({ text: val, value: val })),
      onFilter: (value, record) => record.fullName === value,
      width: 100,
    },
    {
      key: 'user-email',
      title: 'Email',
      dataIndex: 'email',
      filteredValue: filteredInfo.email || null,
      sorter: (a, b) => a.email.localeCompare(b.email),
      filters: initialFilters.email?.map((val) => ({ text: val, value: val })),
      onFilter: (value, record) => record.email === value,
      width: 100,
    },
    {
      key: 'user-type',
      title: 'User Role',
      dataIndex: 'userType',
      filteredValue: filteredInfo.userType || null,
      sorter: (a, b) => a.userType - b.userType,
      render: (text, record) => (text == 1 ? 'Account Owner' : text == 2 ? 'Admin' : text == 3 ? 'Member' : 'Guest'),
      filters: initialFilters.userType?.map((val) => ({
        text: val == 1 ? 'Account Owner' : val == 2 ? 'Admin' : val == 3 ? 'Member' : 'Guest',
        value: val,
      })),
      onFilter: (value, record) => record.userType === value,
      width: 100,
    },
    {
      key: 'user-project',
      title: 'Projects',
      dataIndex: 'account_user_project_access',
      filteredValue: filteredInfo.account_user_project_access || null,
      render: (text, record) => text.map((item) => item.user_project_access !== null ? item.user_project_access.projectName : []).join(', '),
      filters: getUniqueProjects(initialFilters.account_user_project_access),
      onFilter: (value, record) => {
        // Convert `value` to a number since `projectId` might be a number
        const selectedProjectId = Number(value);

        // Check if `record.account_user_project_access` contains the selected `projectId`
        return record.account_user_project_access.some((item) => item.projectId === selectedProjectId);
      },
      width: 200,
    },
    {
      key: 'user-action',
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <Space>
          <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => editRecord(record)}>
            <FontAwesomeIcon icon={regular("pencil")} style={{ color: '#C9CDD6', fontSize: '20px' }} />
          </Button>
          {(record.userType != 1 && record.userType > parseInt(localStorage.getItem('user_type')) && record.id !== parseInt(localStorage.getItem('user_id'))) && (
            <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => showDeleteModal(record)}>
              <FontAwesomeIcon icon={regular("trash-alt")} style={{ color: 'red', fontSize: '20px' }} />
            </Button>
          )}
        </Space>
      ),
      width: 50,
    },
  ];

  const deletedColumns = [
    {
      key: 'user-fullname-delete',
      title: 'User',
      dataIndex: 'fullName',
      filteredValue: filteredInfo.fullName || null,
      // render: (text, record) => <RandomColorAvatar text={text} record={record} />,
      render: (text, record) => <><AccountUserProfileAvatar {...props} audience={record} /><span style={{ marginLeft: '10px' }}>{text}</span></>,
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      width: 100,
    },
    {
      key: 'user-email-delete',
      title: 'Email',
      dataIndex: 'email',
      filteredValue: filteredInfo.email || null,
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: 100,
    },
    {
      key: 'user-type-delete',
      title: 'User Role',
      dataIndex: 'userType',
      filteredValue: filteredInfo.userType || null,
      sorter: (a, b) => a.userType - b.userType,
      render: (text, record) => (text == 1 ? 'Account Owner' : text == 2 ? 'Admin' : text == 3 ? 'Member' : 'Guest'),
      width: 100,
    },
    {
      key: 'user-project-delete',
      title: 'Projects',
      dataIndex: 'account_user_project_access',
      filteredValue: filteredInfo.account_user_project_access || null,
      render: (text, record) => text.map((item) => item.project_access !== null ? item.project_access.projectName : []).join(', '),
      width: 200,
    },
    {
      key: 'user-action-delete',
      title: 'Action',
      dataIndex: 'action',
      render: (text, record, index) => (
        <Space>
          {parseInt(record.userType) < 4 && hasUserLicenses ? (
            <Button 
              type="amber-primary"
              style={{paddingTop: "0px"}}
              onClick={() => showDeleteModal(record)}
            >
              Retain User 
            </Button>
          ) : (
            parseInt(record.userType) < 4 && (
              <Button 
                type="ocm-default"
                style={{paddingTop: "0px"}}
                onClick={() => {
                  navigate(`/${organizationalSlug}/${isPaidAccount ? `subscription/update-subscription/AddUser/${record.id}` : 'subscribe-now'}`)
                }}
              >
                Add License 
              </Button>
            )
          )}
          {parseInt(record.userType) === 4 && hasGuestLicenses ? (
            <Button 
              type="ocm-default"
              style={{paddingTop: "0px"}}
            >
              Retain Guest
            </Button>
          ) : (
            parseInt(record.userType) === 4 && (
              <Button 
                type="ocm-default"
                style={{paddingTop: "0px"}}
              >
                Add License 
              </Button>
            )
          )}
          <Button 
            type="primary"
            danger 
            onClick={() => showDeleteModal(record)}
          >
            Delete Now
          </Button>
        </Space>
      ),
      width: 100,
    },
  ];

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
      switch (action.type) {
        case 'VERIFY_USER_ERR':
          return { ...state, error: true,messages: action.err };
        case 'CONVERT_TO_TRIAL_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
      dispatch(
          verifyUser({}, (response) => {
            if (response.status === 200) {
              if (parseInt(response.data.remainingUser) > 0)
              {
                setHasUserLicenses(true);
              }
              else
              {
                setHasUserLicenses(false);
              }
              if (parseInt(response.data.remainingGuest) > 0)
              {
                setHasGuestLicenses(true);
              }
              else
              {
                setHasGuestLicenses(false);
              }
            } 
            else 
            {
              openError('topLeft', 'Error', response.err);
              apiResponseCallback(response);
            }
          }),
        );
  }, [dispatch, hasUserLicenses]);

  useEffect(() => {
    const retrieveData = async () => {
      await fetchData();
      await getAccountInfo();
      if (!accountUserData.loaded && accountUserData.accountUsers.length === 0)
      {
        await getUsers();
      }
    };
    props.setOpenKeys(['user-management']);
    retrieveData().catch(console.error);
  }, []);

  const isPaidAccount = localStorage.getItem('is_paid') === 'true';

  return (
    <>
      {contextHolder}
      <Main>
        <UserStyle>
          <Row justify="center" gutter={25} style={{width: "100%"}}>
            <Col xxl={24} xl={24} md={24}>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <Tabs
                  className="toolTabStyle"
                  centered
                  size={'large'}
                  tabBarGutter={25}
                  items={toolsTab.map((item, i) => {
                    return {
                      label: (
                        <div className="ant-page-header-heading-title">
                          {item.tab_name}
                        </div>
                      ),
                      key: item.tab_key,
                      children:
                        accountInfo != undefined ? (
                          <div style={{ padding: '60px' }}>
                            <Row gutter={30}>
                              <Col span={10}>
                                <Row className="box-all">
                                  <Col className="box-icon" span={8}>
                                    <FontAwesomeIcon
                                      icon="fa-duotone fa-users"
                                      style={{
                                        fontSize: '3.5rem',
                                        '--fa-primary-color': `#2B64AC`,
                                        '--fa-secondary-color': `#A5BEDC`,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Col>
                                  <Col span={16} className="countDiv">
                                    {accountInfo.account_users.length} User{accountInfo.account_users.length > 1 && 's'}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={4}></Col>
                              <Col span={10}>
                                <Row className="box-all">
                                  <Col className="box-icon" span={8}>
                                    <FontAwesomeIcon
                                      icon="fa-duotone fa-folder-plus"
                                      style={{
                                        fontSize: '3.5rem',
                                        '--fa-primary-color': `#2B64AC`,
                                        '--fa-secondary-color': `#A5BDDC`,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Col>
                                  <Col span={16} className="countDiv">
                                    {accountInfo.account_projects.length} Project{accountInfo.account_projects.length > 1 && 's'}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <div className="container-create-button">
                              <Button
                                style={{
                                  backgroundColor: '#4C4755',
                                  height: '40px',
                                  fontSize: '18px',
                                  color: 'white',
                                  borderRadius: '10px',
                                }}
                                onClick={showModal}
                              >
                                <FontAwesomeIcon icon={regular("plus")} style={{ marginRight: '8px' }} />
                                Create User
                              </Button>
                            </div>

                            {!accountUserData.loaded ? (
                              <div style={{ padding: '60px', textAlign: "center" }}>
                                <FontAwesomeIcon
                                  icon={duotone('spinner-third')}
                                  spin
                                  size="5x"
                                  style={{ '--fa-primary-color': '#3A3540', '--fa-secondary-color': '#3A3540', padding: '20px 10px' }}
                                />
                              </div>
                            ) : (
                              <Table
                                style={{ marginTop: '20px' }}
                                pagination={{
                                  pageSize: 10,
                                  hideOnSinglePage: true,
                                }}
                                columns={columns}
                                dataSource={accountUserData.accountUsers}
                                rowKey={(record) => record.id}
                                onChange={handleChange}
                                scroll={{
                                  x: 1400,
                                  y: 600,
                                }}
                              />
                            )}
                            {Object.keys(filteredInfo).length != 0 && (
                              <div style={{textAlign:"center", marginTop:"30px"}}>
                                <Button type="primary" onClick={clearFilters}>
                                  Clear Filters
                                </Button>
                              </div>
                            )}
                            {(!hasUserLicenses && !hasGuestLicenses) && (
                              <div id="consumed_licenses" style={{paddingTop: "15px"}}>
                                <Alert
                                  id=""
                                  message={<Title level={4} type='danger'>You have consumed all your user licenses.</Title>}
                                  description={
                                    <Title level={5}>To acquire additional User Licenses, <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>click here</Link> to add them to your account</Title>
                                  }
                                  type="error"
                                  showIcon
                                />      
                              </div>
                            )}
                            {accountUserData.loaded && accountUserData.deletedAccountUsers.length > 0 && (
                                <div style={{marginTop: "50px", padding: "20px", textAlign: "left", border: "1px solid #ccc"}}>
                                  <Title level={3} type={'danger'} style={{paddingBottom: "30px"}}>Deleted User (Licenses Reduction)</Title>
                                  <Text type={'secondary'} style={{paddingBottom: "30px", fontSize: "20px"}}>The following users are marked for deletion (These users will automatically deleted in {process.env.REACT_APP_REDUCE_LICENSES_DELETED_TIME} days)</Text>
                                  <Table
                                    style={{ marginTop: '20px' }}
                                    pagination={{
                                      pageSize: 10,
                                      hideOnSinglePage: true,
                                    }}
                                    columns={deletedColumns}
                                    dataSource={accountUserData.deletedAccountUsers}
                                    rowKey={(record) => record.id}
                                    onChange={handleChange}
                                    scroll={{
                                      x: 1400,
                                      y: 600,
                                    }}
                                  />
                                </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        ),
                    };
                  })}
                />
              </Suspense> 
            </Col>
          </Row>
        </UserStyle>
        <OCMModal>
          <Modal
            style={{
              margin: '20px 0px',
            }}
            className="add-change"
            title={
              <OCMModalHeader>
                <div className="user-info-header-panel">
                  <h1 className="header-title">Delete User</h1>
                  <div className="ribon none"></div>
                </div>
              </OCMModalHeader>
            }
            centered
            closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={() => setIsModalVisible(false)}
            wrapClassName={'ocm-modal'}
            footer={[
              <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                Cancel
              </Button>,
              <Button key="delete" type="primary" danger onClick={handleDelete}>
                Delete
              </Button>,
            ]}
          >
            <div style={{ padding: '20px 30px' }}>
              <p style={{ fontSize: '22px' }}>Are you sure you want to delete {toBeDeleted?.fullName}'s account?</p>
              <Text type={'secondary'} level={2} style={{
                display: "inline-block",
                fontSize: 17,
                textAlign: "center",
                width: "100%"
              }}>Note: This action cannot be undone.</Text>
            </div>
          </Modal>
        </OCMModal>
        {accountInfo && (
          <CreateUserModal
            isModalVisible={isModalEditVisible}
            setIsModalVisible={setIsModalEditVisible}
            handleSave={handleSave}
            rearrangeProject={props.rearrangeProject}
            cancelData={cancelData}
            userData={userData}
            isEditing={userData ? true : false}
            hasUserLicenses={hasUserLicenses}
            hasGuestLicenses={hasGuestLicenses}
            accountOwnerId={accountInfo.accountUserId}
            organizationName={accountInfo.organizationalName}
            projects={accountInfo.account_projects}
            setUserLiceses={(val) => setHasUserLicenses(val)}
            setGuestLiceses={(val) => setHasGuestLicenses(val)}
          />
        )}
      </Main>
    </>
  );
}

export default UserManagement;
